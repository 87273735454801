<template>
  <div
    v-if="isLoading"
    style="
      height: 200px;
      display: flex;
      justify-content: center;
      align-items: center;
    "
  >
    <div class="text-center px-5 pb-7">
      <img
        alt="Logo"
        class="max-h-25px"
        src="/media/loading_icon.gif"
        style="width: 35px"
      />
    </div>
  </div>
  <Form
    v-else
    id="create_jobposting_form"
    :validation-schema="form"
    class="form"
    @submit="onSubmit"
  >
    <div class="row g-7">
      <!--begin::Contacts-->
      <div class="card card-flush h-lg-100" id="kt_contacts_main">
        <!--begin::Card header-->
        <div class="card-header pt-3" id="kt_chat_contacts_header">
          <!--begin::Card title-->
          <!--begin::Title-->
          <h2 class="fw-bolder d-flex align-items-center text-dark">
            {{ $t("modals.interview.interviewDetails") }}
            <i
              class="fas fa-exclamation-circle ms-2 fs-7"
              data-bs-toggle="tooltip"
              :title="$t('modals.interview.updateInterviewToolTip')"
            ></i>
          </h2>
          <!--end::Title-->

          <!--end::Card title-->
        </div>
        <!--end::Card header-->

        <!--begin::Card body-->
        <div class="card-body pt-5">
          <!--begin::Input group-->
          <div class="mb-10 fv-row">
            <!--begin::Label-->
            <label class="form-label mb-3 required">
              {{ $t("modals.interview.interviewTitle") }}</label
            >
            <!--end::Label-->

            <!--begin::Input-->
            <Field
              type="text"
              class="form-control form-control-lg form-control-solid"
              name="title"
              placeholder=""
              v-model="interview.title"
            />
            <ErrorMessage
              name="title"
              class="fv-plugins-message-container invalid-feedback"
            ></ErrorMessage>
            <!--end::Input-->
          </div>
          <!--end::Input group-->

          <!--begin::Input group-->
          <div class="mb-10 fv-row">
            <!--begin::Label-->
            <label class="form-label mb-3 required">{{
              $t("modals.interview.description")
            }}</label>
            <!--end::Label-->
            <Field
              as="textarea"
              type="text"
              class="form-control form-control-lg form-control-solid"
              name="description"
              :placeholder="$t('modals.interview.descriptionPlaceholder')"
              v-model="interview.description"
            />
            <ErrorMessage
              name="description"
              class="fv-plugins-message-container invalid-feedback"
            ></ErrorMessage>
            <!--end::Input-->
          </div>
          <!--end::Input group-->
          <!-- Gönder Butonu ve Diğer İhtiyaçlar -->
          <div class="d-flex justify-content-center">
            <button
              type="submit"
              id="kt_account_profile_details_submit"
              ref="submitButton"
              class="btn btn-primary"
            >
              <span class="indicator-label">{{
                $t("common.button.update")
              }}</span>
              <span class="indicator-progress">
                {{ $t("common.pleaseWait") }}
                <span
                  class="spinner-border spinner-border-sm align-middle ms-2"
                ></span>
              </span>
            </button>
          </div>
        </div>

        <!--end::Card body-->
      </div>

      <div class="row mt-7">
        <!-- Sol Column: Yeni Soru Ekle -->
        <div class="col-md-4">
          <div class="card card-flush h-lg-100">
            <!--begin::Card header-->
            <div class="card-header pt-3" id="kt_chat_contacts_header">
              <h2 class="fw-bolder d-flex align-items-center text-dark">
                {{ $t("modals.interview.addQuestion") }}
              </h2>
            </div>
            <!--end::Card header-->

            <!--begin::Card body-->
            <div class="card-body pt-5">
              <div class="fv-row mb-5">
                <label class="fs-5 fw-bold form-label mb-2">{{ $t("modals.interview.question") }}:</label>
                <div class="d-flex align-items-center mb-3">
                  <textarea
                    class="form-control form-control-lg form-control-solid me-3"
                    v-model="newQuestion.questionText"
                    @keyup.enter="addQuestion"
                    :placeholder="$t('modals.interview.writeQuestionPressEnter')"
                    style="flex-grow: 1"
                  ></textarea>
                </div>
              </div>
              <div class="fv-row mb-5">
                <div class="d-flex align-items-center mb-3">
                  <select
                    v-model="newQuestion.questionType"
                    class="form-select form-select-lg"
                    aria-label="Soru Türü"
                  >
                    <option :value="QuestionTypes.OPEN_ENDED">{{ $t("modals.interview.openEnded") }}</option>
                    <option :value="QuestionTypes.SINGLE_CHOICE">
                      {{ $t("modals.interview.singleChoice") }}
                    </option>
                    <option :value="QuestionTypes.MULTIPLE_CHOICE">
                      {{ $t("modals.interview.multipleChoice") }}
                    </option>
                  </select>

                  <button
                    @click.prevent="addQuestion"
                    class="btn btn-primary ms-2"
                    type="button"
                  >
                  {{ $t("common.button.add") }}
                  </button>
                </div>

                <div
                  v-if="
                    newQuestion.questionType === QuestionTypes.SINGLE_CHOICE ||
                    newQuestion.questionType === QuestionTypes.MULTIPLE_CHOICE
                  "
                  class="mb-3"
                >
                  <input
                    type="text"
                    v-model="newOptionText"
                    @keyup.enter="addOption"
                    class="form-control form-control-sm me-3"
                    :placeholder="$t('modals.interview.forAddQualification')"
                    v-on:keydown.enter.prevent
                  />
                  <ul class="list-group mt-2">
                    <li
                      v-for="(
                        option, index
                      ) in newQuestion.interviewQuestionOptions"
                      :key="index"
                      class="
                        list-group-item
                        d-flex
                        justify-content-between
                        align-items-center
                      "
                    >
                      {{ option.optionText }}
                      <button
                        type="button"
                        @click="removeOption(index)"
                        class="btn btn-sm btn-danger"
                      >
                      {{ $t("common.button.delete") }}
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <!--end::Card body-->
          </div>
        </div>

        <!-- Sağ Column: Mevcut Soruları Göster -->
        <div class="col-md-8">
          <div class="card card-flush h-lg-100">
            <!--begin::Card header-->
            <div class="card-header pt-3" id="kt_chat_contacts_header">
              <h2 class="fw-bolder d-flex align-items-center text-dark">
                {{ $t("modals.interview.interviewQuestions") }}
              </h2>
            </div>
            <!--end::Card header-->

            <!--begin::Card body-->
            <div class="card-body pt-5">
              <div class="fv-row mb-5">
                <div v-if="questions.length > 0">
                  <div
                    v-for="(question, qIndex) in questions"
                    :key="qIndex"
                    class="mb-5 border p-4 rounded shadow-sm"
                  >
                    <!-- Soru Başlığı -->
                    <label class="form-label fw-bold"
                      >{{ $t("modals.interview.question") }} {{ qIndex + 1 }}</label
                    >
                    <input
                      type="text"
                      v-model="question.questionText"
                      class="form-control form-control-solid mb-3"
                      placeholder="Soruyu düzenleyin"
                      @change="
                        updateQuestion(question.id, question.questionText)
                      "
                                          v-on:keydown.enter.prevent
                    />

                    <!-- Soru Türü -->
                    <div class="mb-3">
                      <label class="form-label mb-0">{{ $t("modals.interview.questionType") }}</label>
                      <div class="form-text">
                        <span
                          v-if="
                            question.questionType === QuestionTypes.OPEN_ENDED
                          "
                          >{{ $t("modals.interview.openEnded") }}</span
                        >
                        <span
                          v-if="
                            question.questionType ===
                            QuestionTypes.SINGLE_CHOICE
                          "
                          >{{ $t("modals.interview.singleChoice") }}</span
                        >
                        <span
                          v-if="
                            question.questionType ===
                            QuestionTypes.MULTIPLE_CHOICE
                          "
                          >{{ $t("modals.interview.multipleChoice") }}</span
                        >
                      </div>
                    </div>

                    <!-- Seçenekler -->
                    <div
                      v-if="
                        question.questionType === QuestionTypes.SINGLE_CHOICE ||
                        question.questionType === QuestionTypes.MULTIPLE_CHOICE
                      "
                      class="mb-3"
                    >
                      <label class="form-label">{{ $t("modals.interview.responseOptions") }}</label>
                      <div
                        v-for="(
                          option, oIndex
                        ) in question.interviewQuestionOptions"
                        :key="oIndex"
                        class="d-flex align-items-center mb-2"
                      >
                        <input
                          type="text"
                          v-model="
                            question.interviewQuestionOptions[oIndex].optionText
                          "
                          class="form-control form-control-sm me-3"
                          readonly
                        />
                        <button
                          type="button"
                          @click="
                            removeExistingOption(
                              question.interviewQuestionOptions[oIndex].id
                            )
                          "
                          class="btn btn-sm btn-danger"
                        >
                        {{ $t("common.button.delete") }}
                        </button>
                      </div>
                      <div class="d-flex align-items-center">
                        <input
                          type="text"
                          v-model="newOptionText"
                          class="form-control form-control-sm me-3"
                          placeholder="Yeni opsiyon ekle"
                          @keyup.enter="addOptionToQuestion(question.id)"
                          v-on:keydown.enter.prevent
                        />
                        <button
                          @click.prevent="addOptionToQuestion(question.id)"
                          class="btn btn-secondary btn-sm"
                        >
                        {{ $t("common.button.add") }}
                        </button>
                      </div>
                    </div>

                    <!-- Soru Silme Butonu -->
                    <button
                      type="button"
                      @click="removeQuestion(question.id)"
                      class="btn btn-danger btn-sm mt-3"
                    >
                    {{ $t("modals.interview.deleteQuestion") }}
                    </button>
                  </div>
                </div>

                <div v-else class="text-gray-400 fw-bold fs-7">
                  {{ $t("modals.interview.zeroQuestion") }}
                </div>
              </div>
            </div>
            <!--end::Card body-->
          </div>
        </div>
      </div>
      <!--end::Contacts-->
    </div>
  </Form>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import { Field, ErrorMessage, Form } from "vee-validate";
import * as Yup from "yup";
import { VueEditor } from "vue3-editor";
import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import SwalNotification from "@/presentation/plugins/SwalNotification";
import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";
import Swal from "sweetalert2";
import { useRoute, useRouter } from "vue-router";
import { UpdateInterviewModel } from "@/domain/interviewer/update-interview/model/UpdateInterviewModel";
import { InterviewQuestionModel } from "@/domain/interviewer/model/InterviewQuestionsModel";
import { InterviewDetailModel } from "@/domain/interviewer/interview-detail/model/InterviewDetailModel";
import { AddQuestionModel } from "@/domain/interviewer/update-interview/addQuestion/model/AddQuestionModel";
import { AddQuestionOptionModel } from "@/domain/interviewer/update-interview/addQuestionOption/model/AddQuestionOptionModel";
import { DeleteQuestionOptionModel } from "@/domain/interviewer/update-interview/deleteQuestionOption/model/DeleteQuestionOptionModel";
import { DeleteQuestionModel } from "@/domain/interviewer/update-interview/deleteQuestion/model/DeleteQuestionModel";
import { UpdateQuestionModel } from "@/domain/interviewer/update-interview/updateQuestion/model/UpdateQuestionModel";
import { QuestionTypes } from "@/domain/constants/QuestionTypes";
// import { InterviewQuestionListModel } from "@/domain/interviewer/model/InterviewQuestionListModel";

export default defineComponent({
  name: "create-jobposting",
  components: {
    Form,
    Field,
    ErrorMessage,
    VueEditor,
  },
  props: {
    interviewId: Number,
  },
  setup(props) {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const isLoading = ref<boolean>(false);

    const interviewId = ref<any>(Number(route.params["id"]));

    const swalNotification = new SwalNotification();

    const interviewerController =
      store.state.ControllersModule.interviewerController;

    const interviewUpdateModel = ref<UpdateInterviewModel>({
      id: props.interviewId,
    });

    const interview = ref<InterviewDetailModel>({});

    const newQuestion = ref<InterviewQuestionModel>({
      questionText: "",
      questionType: QuestionTypes.OPEN_ENDED,
      interviewQuestionOptions: [],
    });

    const questions = ref<InterviewQuestionModel[]>([]);

    const newOptionText = ref("");

    const addQuestion = () => {
      if (!newQuestion.value.questionText) {
        swalNotification.error(
          t("modals.interview.pleaseWriteQuestion"),
          t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
        );
        return;
      } else if (
        (newQuestion.value.questionType == QuestionTypes.SINGLE_CHOICE ||
          newQuestion.value.questionType == QuestionTypes.MULTIPLE_CHOICE) &&
        newQuestion.value.interviewQuestionOptions.length == 0
      ) {
        swalNotification.error(
          t("modals.interview.pleaseAddOptions"),
          t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
        );
        return;
      } else {
        const addQuestionModel: AddQuestionModel = {
          interview: interviewId.value,
          questionText: newQuestion.value.questionText,
          questionType: newQuestion.value.questionType,
          interviewQuestionOptions: newQuestion.value.interviewQuestionOptions,
        };

        interviewerController
          .addQuestion(addQuestionModel)
          .then((response) => {
            if (response.isSuccess) {
              Swal.fire({
                text: t(SWAL_MESSAGES.SUCCESS.SUCCESS_ADD_QUESTION),
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: t("common.button.ok"),
                customClass: {
                  confirmButton: "btn fw-bold btn-light-primary",
                },
                heightAuto: false,
              });
              getInterviewDetail();
            } else {
              swalNotification.error(
                response.error.cause.cause +
                  t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                  response.error.code +
                  response.error.cause.code,
                t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
              );
            }
            isLoading.value = false;
          })
          .catch((e) => {
            swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
          });

        newQuestion.value = {
          questionText: "",
          questionType: QuestionTypes.OPEN_ENDED,
          interviewQuestionOptions: [],
        };
        newOptionText.value = "";
      }
    };

    // Opsiyon ekleme fonksiyonu
    const addOption = () => {
      if (newOptionText.value.trim() !== "") {
        newQuestion.value.interviewQuestionOptions.push({
          id: 0,
          optionText: newOptionText.value.trim(),
        });
        newOptionText.value = "";
      }
    };

    const addOptionToQuestion = (questionId) => {
      const addQuestionOptionModel: AddQuestionOptionModel = {
        interviewQuestion: questionId,
        optionText: newOptionText.value,
      };

      interviewerController
        .addQuestionOption(addQuestionOptionModel)
        .then((response) => {
          if (response.isSuccess) {
            Swal.fire({
              text: t(SWAL_MESSAGES.SUCCESS.SUCCESS_ADD_QUESTION_OPTION),
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: t("common.button.ok"),
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary",
              },
              heightAuto: false,
            });
            getInterviewDetail();
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });

      newOptionText.value = "";
    };

    const updateQuestion = (questionId, text) => {
      const updateQuestionModelModel: UpdateQuestionModel = {
        id: questionId,
        questionText: text,
      };

      interviewerController
        .updateQuestion(updateQuestionModelModel)
        .then((response) => {
          if (response.isSuccess) {
            Swal.fire({
              text: t(SWAL_MESSAGES.SUCCESS.SUCCESS_UPDATE_QUESTION),
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: t("common.button.ok"),
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary",
              },
              heightAuto: false,
            });
            getInterviewDetail();
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });

      newOptionText.value = "";
    };

    const removeOption = (index: number) => {
      newQuestion.value.interviewQuestionOptions.splice(index, 1);
    };

    const removeExistingOption = (optionId) => {
      const deleteQuestionOptionModel: DeleteQuestionOptionModel = {
        id: optionId,
      };

      Swal.fire({
        title: t("swalMessages.ARE_YOU_SURE"),
        text: t("modals.interview.areYouSureAboutDeleteOption"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: t("common.button.yes"),
        cancelButtonText: t("common.button.cancel"),
        heightAuto: false,
      }).then((result) => {
        if (result.isConfirmed) {
          interviewerController
            .deleteQuestionOption(deleteQuestionOptionModel)
            .then((response) => {
              if (response.isSuccess) {
                Swal.fire({
                  text: t(SWAL_MESSAGES.SUCCESS.SUCCESS_DELETE_QUESTION_OPTION),
                  icon: "success",
                  buttonsStyling: false,
                  confirmButtonText: t("common.button.ok"),
                  customClass: {
                    confirmButton: "btn fw-bold btn-light-primary",
                  },
                  heightAuto: false,
                });
                getInterviewDetail();
              } else {
                swalNotification.error(
                  response.error.cause.cause +
                    t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                    response.error.code +
                    response.error.cause.code,
                  t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
                );
              }
            })
            .catch((e) => {
              swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
            });
        }
      });
    };

    const removeQuestion = (questionId) => {
      const deleteQuestionModel: DeleteQuestionModel = {
        id: questionId,
      };
      Swal.fire({
        title: t("swalMessages.ARE_YOU_SURE"),
        text: t("modals.interview.areYouSureAboutDeleteQuestion"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: t("common.button.yes"),
        cancelButtonText: t("common.button.cancel"),
        heightAuto: false,
      }).then((result) => {
        if (result.isConfirmed) {
          interviewerController
            .deleteQuestion(deleteQuestionModel)
            .then((response) => {
              if (response.isSuccess) {
                Swal.fire({
                  text: t(SWAL_MESSAGES.SUCCESS.SUCCESS_DELETE_QUESTION),
                  icon: "success",
                  buttonsStyling: false,
                  confirmButtonText: t("common.button.ok"),
                  customClass: {
                    confirmButton: "btn fw-bold btn-light-primary",
                  },
                  heightAuto: false,
                });
                getInterviewDetail();
              } else {
                swalNotification.error(
                  response.error.cause.cause +
                    t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                    response.error.code +
                    response.error.cause.code,
                  t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
                );
              }
            })
            .catch((e) => {
              swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
            });
        }
      });
    };

    const { t } = useI18n();
    const featureInput = ref("");
    const features = ref<string[]>([]);

    const form = Yup.object().shape({
      title: Yup.string()
        .required(t("validators_error.required"))
        .label("title"),
      description: Yup.string()
        .required(t("validators_error.required"))
        .label("description"),
    });

    const getInterviewDetail = async () => {
      questions.value = [];
      isLoading.value = true;

      const interviewDetailModel: InterviewDetailModel = {
        id: interviewId.value,
      };

      interviewerController
        .getInterviewDetail(interviewDetailModel)
        .then((response) => {
          if (response.isSuccess) {
            Object.assign(interview.value, response.getValue());

            if (interview.value.interviewQuestions)
              Object.assign(
                questions.value,
                interview.value.interviewQuestions
              );
          } else {
            if (!interview.value.id) {
              router.push({ name: "500" });
            }
          }
          isLoading.value = false;
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const onSubmit = () => {
      interviewUpdateModel.value = {
        id: interviewId.value,
        title: interview.value.title,
        description: interview.value.description,
      };

      interviewerController
        .updateInterview(interviewUpdateModel.value)
        .then((response) => {
          if (response.isSuccess) {
            Swal.fire({
              title: t("swalMessages.SUCCESSFUL"),
              text: t(SWAL_MESSAGES.SUCCESS.SUCCESS_UPDATE_INTERVIEW),
              icon: "success",
              heightAuto: false,
            });
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    onMounted(() => {
      setCurrentPageTitle(t("modals.interview.title"));
      getInterviewDetail();
    });

    return {
      onSubmit,
      featureInput,
      features,
      interview,
      form,
      newQuestion,
      questions,
      newOptionText,
      addQuestion,
      updateQuestion,
      addOption,
      addOptionToQuestion,
      removeOption,
      removeExistingOption,
      removeQuestion,
      interviewUpdateModel,
      isLoading,
      QuestionTypes,
    };
  },
});
</script>

<style>
body.swal2-height-auto {
  height: 100% !important;
}
</style>

